function setDate (date) {
  const newDate = new Date(date)
  newDate.setHours(0, 0, 0, 0)
  return newDate
}

export default class TrailingDates {
  constructor (date = new Date(), interval = '1W', format = 'sv-SE') {
    this.dateList = []
    this.format = format
    this.$initIntervals(interval)
    this.refDate = setDate(date)
    this.populateDates()
  }

  $initIntervals (interval) {
    const string = interval.padStart(3, '0')
    this.numberInterval = Number(string.substring(0, 2))
    this.letterInterval = string.substring(2)
  }

  get date () {
    return setDate(this.refDate)
  }

  findStopDate () {
    const { date, letterInterval, numberInterval } = this
    if (letterInterval === 'Y') {
      date.setFullYear(date.getFullYear() - numberInterval)
      return date
    }
    if (letterInterval === 'M') {
      date.setMonth(date.getMonth() - numberInterval)
      return date
    }
    if (letterInterval === 'W') {
      const days = 7 * numberInterval
      date.setDate(date.getDate() - days)
      return date
    }
    date.setDate(date.getDate() - numberInterval)
    return date
  }

  dateRefNotSuprpassed (currentDate) {
    return this.refDate >= currentDate
  }

  incrementDate (currentDate) {
    const { letterInterval } = this
    if (letterInterval === 'Y') {
      currentDate.setMonth(currentDate.getMonth() + 1)
      return
    }
    currentDate.setDate(currentDate.getDate() + 1)
  }

  populateDates () {
    const stopDate = this.findStopDate()
    const currentDate = new Date(stopDate)
    while (this.dateRefNotSuprpassed(currentDate)) {
      this.dateList.push(Window.getCurrentDate(currentDate, this.format))
      this.incrementDate(currentDate)
    }
  }

  get dates () {
    return this.dateList
  }
}