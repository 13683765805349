<template lang="pug">
  div.chart-container
    canvas(
      ref="revenueSourceRevenueChart"
    )
</template>
<style lang="sass" scoped>
  .chart-container
      height: 400px
</style>
<script>
import Chart from 'chart.js'
import TrailingDates from '@/libs/classes/TrailingDates'

if (!localStorage.hiddenSaleReports) localStorage.hiddenSaleReports = '[]'

let hiddenSaleReports = JSON.parse(localStorage.hiddenSaleReports)
let nonReactiveReportType = 'revenues'

function storeToHiddenSaleLocaleStorage ({ hidden, dataset }) {
  const { label } = dataset
  if (!hidden) {
    hiddenSaleReports.push(label)
    localStorage.hiddenSaleReports = JSON.stringify(hiddenSaleReports)
    return
  }
  hiddenSaleReports = hiddenSaleReports.filter(item => item !== label)
  localStorage.hiddenSaleReports = JSON.stringify(hiddenSaleReports)
}

export default {
  name: 'RevenueSourceSalesChart',
  props: {
    reportType: {
      type: String,
      default: 'revenues',
    },
    salesData: {
      type: Array,
      default: () => [],
    },
    hideData: {
      type: Array,
      default: () => [],
    },
    interval: {
      type: String,
      default: '1W',
    },
    date: {
      type: Date,
      default: Window.getCurrentDate(),
    },
  },
  data () {
    return {
      chart: null,
    }
  },
  computed: {
    options () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'right',
          labels: {
            boxWidth: 5,
            usePointStyle: true,
          },
          onClick (e, item) {
            const { chart } = this
            const index = item.datasetIndex
            const { hidden = false, ...dataset } = chart.data.datasets[index]
            storeToHiddenSaleLocaleStorage({ hidden, dataset })
            chart.data.datasets[index].hidden = !hidden
            chart.update()
          },
        },
        tooltips: {
          callbacks: {
            title: this.tooltipCallbackTitle,
            label (tooltipItem, data) {
              const { datasetIndex, value } = tooltipItem
              const { label } = data.datasets[datasetIndex]
              return `${label} : ${Number(value).phpFormat()}` 
            },
          },
        },
        scales: {
          xAxes: [{
            ticks: {
              maxRotation: 0,
              minRotation: 0,
              callback: this.ticksCallback,
            },
            gridLines: {
              drawOnChartArea: false,
            },
          }],
          yAxes: [{
            ticks: {
              callback (val) {
                return val.phpFormat()
              },
            },
            gridLines: {
              drawOnChartArea: false,
            },
          }],
        },
      }
    },
    labels () {
      const trailingDates = new TrailingDates(this.date, this.interval)
      return trailingDates.dates
    },
    datasets () {
      return this.salesData.map(item => ({
        data: this.setDataList(item),
        borderColor: item.pdf_config.theme_hex,
        label: item.code,
        lineTension: 0,
        backgroundColor: 'rgba(0, 0, 0, 0)',
        hidden: hiddenSaleReports.includes(item.code), 
      }))
    },
    intervalString () {
      const string = this.interval.padStart(3, '0')
      return string.substring(2)
    },
    intervalNumber () {
      const string = this.interval.padStart(3, '0')
      return Number(string.substring(0, 2))
    },
  },
  watch: {
    datasets (val, old) {
      this.updateData()
    },
    reportType (val) {
      nonReactiveReportType = val
    },
  },
  mounted () {
    this.initChart()
  },
  methods: {
    getReportType () {
      return this.reportType
    },
    tooltipCallbackTitle (tooltipItem) {
      const { label } = tooltipItem.first()
      if (this.intervalString === 'Y') return label.dateFormat({ year: 'numeric', month: 'short', day: false })
      return label.dateFormat({ year: false, month: 'short' })
    },
    updateData () {
      const { datasets, labels } = this
      const data = { datasets, labels }
      this.chart.data = data
      this.chart.update()
    },
    setDataList (revenueSource) {
      const { intervalString } = this
      const tempItems = new Array(...revenueSource[`daily_${nonReactiveReportType}`])
      return this.labels.map(label => {
        const item = tempItems.first()
        if (!item) return 0
        const itemDate = intervalString === 'Y' ? item.date.dateFormat({ day: false }) : item.date
        const labelDate = intervalString === 'Y' ? label.dateFormat({ day: false }) : label
        if (itemDate !== labelDate) return 0
        tempItems.shift()
        return item.amount
      })
    },
    initChart () {
      const ctx = this.$refs.revenueSourceRevenueChart
      const { options, datasets, labels } = this
      const data = { datasets, labels }
      const config = {
        type: 'line',
        data,
        options,
      }
      this.chart = new Chart(ctx, config)
    },
    ticksCallback (val, index) {
      const { intervalString, intervalNumber } = this
      if (intervalString === 'W') return val.dateFormat({ year: false, month: 'short' })
      if (intervalString === 'Y') {
        if (intervalNumber === 5) return index % 4 === 0 ? val.dateFormat({ month: 'short', day: false }) : ''
        return index % 2 === 0 ? val.dateFormat({ month: 'short', day: false }) : ''
      }
      return index % 4 === 0 ? val.dateFormat({ year: false, month: 'short' }) : ''
    },
  },
}
</script> 